const cookies = require("cookies-js");
const api_url = ((process.env.VUE_APP_USE_HTTPS === "true" && "https://") || "http://") +
process.env.VUE_APP_API_DOMAIN;

exports.login = async (store) => {
    try {
        var loginWindow = window.open(api_url + "/auth/", '_blank', 'width=800, height=600');
        if (loginWindow.focus) {
          loginWindow.focus();
        }

        let auth_token = "";
        window.addEventListener("message", function(event) {
            if (event.origin == api_url) {
                auth_token = event.data;
            }
        })

        while(auth_token == "")
            await new Promise(resolve => {
                setTimeout(function() {
                    resolve();
                }, 250);
            })
        
        cookies.set("auth_token", auth_token, {expiresIn: 60 * 60 * 24 * 7});

        store.dispatch("fetchUser");
    } catch (error) {
        console.log(error.message);
    }
}

exports.logout = (store) => {
    store.commit("setUser", null);

    cookies.expire("auth_token");
}