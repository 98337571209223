const axios = require("axios").default;

exports.state = {
    services: undefined,
}

exports.getters = {
    services: state => state.services
}

exports.actions = {
    fetchServices: async({commit}) => {
        var services = []
        var servicesResponse = await axios.get(((process.env.VUE_APP_USE_HTTPS === "true" && "https://") || "http://") +
        process.env.VUE_APP_API_DOMAIN + "/v1/services");

        console.log(servicesResponse);

        for (var serviceKey in servicesResponse.data) {
            var sectionId = servicesResponse.data[serviceKey].section;
            if (!services[sectionId]) {
                services[sectionId] = []
            }

            services[sectionId].push(servicesResponse.data[serviceKey])
        }

        commit("setServices", services);
    }
}

exports.mutations = {
    setServices: (state, services) => state.services = services ,
}