<template>
  <div>
    <div
      v-for="section in serviceSections"
      :key="section._id"
      class="container"
    >
      <b-card align="center">
        <template #header>
          <h1 class="mb-0">{{ section.name }}</h1>
        </template>
        <div v-if="section.image" class="img-clipper">
          <b-img
            class="clipped"
            :src="
              'https://www.punkturedpiercingnottingham.com/img/' + section.image
            "
            center
            fluid-grow
          ></b-img>
        </div>
        <div v-if="services">
          <!-- <h1 v-for="service in services[section._id]" :key="service._id">
            {{ service.name }}
          </h1> -->
          <div v-for="service in services[section._id]" :key="service._id">
            <b-card
              class="service-card"
              :title="service.name"
              :sub-title="
                parsePrice(service.price) + ' • ' + getTime(service.time)
              "
              align="left"
            >
              <b-card-text>
                {{ service.info }}
              </b-card-text>

              <!-- <b-card-text>A second paragraph of text in the card.</b-card-text> -->

              <a
                v-if="service.link && service.link != ''"
                :href="service.link"
                class="card-link"
                target="blank"
                >View</a
              >
              <!-- <b-link href="#" class="card-link">Another link</b-link> -->
            </b-card>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      api_url:
        ((process.env.VUE_APP_USE_HTTPS === "true" && "https://") ||
          "http://") + process.env.VUE_APP_API_DOMAIN,
    };
  },
  async mounted() {
    await this.fetchServices();
    this.fetchServiceSections();
  },
  methods: {
    ...mapActions(["fetchServiceSections", "fetchServices"]),
    getTime(unix) {
      var seconds = unix;
      var minutes = 0;
      var hours = 0;

      var timeString = "";

      if (seconds == 0) {
        return "Duration Varies";
      }

      while (seconds >= 60) {
        minutes++;
        seconds -= 60;
      }

      while (minutes >= 60) {
        hours++;
        minutes -= 60;
      }

      if (hours > 0) {
        if (hours > 1) {
          timeString += hours + " Hours ";
        } else {
          timeString += hours + " Hour ";
        }
      }

      if (minutes > 0) {
        if (minutes > 1) {
          timeString += minutes + " Minutes ";
        } else {
          timeString += minutes + " Minute ";
        }
      }

      if (seconds > 0) {
        if (seconds > 1) {
          timeString += seconds + " Seconds ";
        } else {
          timeString += seconds + " Second ";
        }
      }

      return timeString;
    },
    parsePrice(price) {
      if (typeof price == "object") {
        if (price.min && price.max) {
          return "£" + price.min + "-£" + price.max;
        } else if (price.value) {
          return "£" + price.value;
        }
      }

      return "Price Varies";
    },
  },
  computed: {
    ...mapGetters(["serviceSections", "services"]),
  },
};
</script>

<style scoped>
.container {
  margin-top: 40px;
}

.img-clipper {
  height: 200px;
  overflow: hidden;
}

.clipped {
  width: 100%;
  transform: translateY(-50%);
}

.card {
  border: none;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.35);
}

.card-body {
  background-color: rgba(249, 185, 252, 0.1);
}

.card-header {
  border: none;
  background-color: rgba(249, 185, 252, 0.3);
}

.service-card {
  margin-top: 10px;
}
</style>