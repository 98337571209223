const axios = require("axios").default;

exports.state = {
    serviceSections: undefined,
}

exports.getters = {
    serviceSections: state => state.serviceSections
}

exports.actions = {
    fetchServiceSections: async({commit}) => {
        var serviceSectionResponse = await axios.get(((process.env.VUE_APP_USE_HTTPS === "true" && "https://") || "http://") +
        process.env.VUE_APP_API_DOMAIN + "/v1/servicesections");

        commit("setServiceSections", serviceSectionResponse.data);
    }
}

exports.mutations = {
    setServiceSections: (state, serviceSections) => (state.serviceSections = serviceSections),
}