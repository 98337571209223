<template>
  <div class="layout">
    <div class="header">
      <b-navbar toggleable="md" variant="faded" type="light" sticky>
        <div class="container">
          <b-navbar-brand class="nav-branding" to="/"
            ><span>Punktured</span>Piercing</b-navbar-brand
          >

          <b-navbar-toggle target="navbar-toggle-collapse">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
              <b-icon v-else icon="chevron-bar-down"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/" exact>Home</b-nav-item>
              <b-nav-item to="/services/" exact>Services</b-nav-item>
              <b-nav-item to="/contact/" exact>Contact Us</b-nav-item>
              <b-nav-item
                v-if="!user && node_env == 'development'"
                v-on:click="login"
                >Login</b-nav-item
              >
              <b-nav-item-dropdown class="caret-white" v-if="user">
                <template v-slot:button-content>{{ user.name }} </template>
                <b-dropdown-item
                  v-if="user.scopes.indexOf('admin') >= 0"
                  to="/dashboard/"
                  exact
                  >Dashboard</b-dropdown-item
                >
                <b-dropdown-item v-on:click="logout">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
    <div class="home-img">
      <div class="jumbotron shadow review">
        <div class="review-inner">
          <div class="review-star-symbol">★</div>
          <div v-if="this.review">
            <h4 class="review-text">{{ this.review.body }}</h4>
            <hr />
            <h6 class="review-name">{{ this.review.name }}</h6>
          </div>
          <div v-if="!this.review">
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="60%"></b-skeleton>
            <hr />
            <b-skeleton width="20%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
const api_url =
  ((process.env.VUE_APP_USE_HTTPS === "true" && "https://") || "http://") +
  process.env.VUE_APP_API_DOMAIN;

const NODE_ENV = process.env.NODE_ENV;

import { mapGetters, mapActions } from "vuex";

import axios from "axios";
import loginservice from "../services/login.service";

export default {
  data() {
    return {
      review: null,
      node_env: NODE_ENV,
    };
  },
  mounted() {
    axios.get(api_url + "/v1/reviews").then((res) => {
      this.review = res.data[Math.floor(Math.random() * res.data.length)];
    });

    this.fetchUser();
  },
  methods: {
    login: async function () {
      await loginservice.login(this.$store);

      this.fetchUser();
    },
    logout: function () {
      loginservice.logout(this.$store);
    },
    ...mapActions(["fetchUser"]),
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style scoped>
.layout {
  display: flex;
  height: 100%;
  flex-flow: column;
}

.header {
  flex: 1;
  display: flex;
  flex-flow: column;
}

.nav-branding {
  font-size: 1.8rem;
}

@media only screen and (max-width: 600px) {
  .nav-branding {
    font-size: 1.4rem;
  }
}

.nav-branding > span {
  font-weight: 700;
  color: #f9b9fc;
}

.nav-branding:hover > span {
  color: #fa9aff;
}

.nav-link {
  color: #252525;
}

.nav-link:hover {
  color: #000000;
}

.nav-link.router-link-exact-active.router-link-active {
  color: #f9b9fc;
  font-weight: 600;
}

.nav-link.router-link-exact-active.router-link-active:hover {
  color: #fa9aff;
}

.home-img {
  flex: 10;
  background-image: url("../assets/home.jpg");
  background-position-y: 3.5%;
  background-size: cover;
  align-self: stretch;
  min-height: 300px;
}

.home-img .jumbotron {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  max-width: 800px;
  margin: 60px auto;
  display: block;

  text-align: center;
}

@media (max-width: 900px) {
  .home-img .jumbotron {
    margin: 60px 40px;
  }
}

.jumbotron.shadow.review .review-inner {
  position: relative;
  margin: 0 auto;
  padding: 10px 20px;
}

.jumbotron.shadow.review .review-star-symbol {
  width: 50px;
  height: 50px;
  background: #fddffe;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  font-size: 45px;
  line-height: 45px;
  position: absolute;
  left: 0;
  right: 0;
  top: -65px;
  color: #fff;
  text-shadow: 0 0 4px rgba(20, 20, 20, 0.2);
}

.review-text {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .review-text {
    font-size: 12px;
  }

  .review-name {
    font-size: 8px;
  }
}

.content {
  flex: 8;
  padding-bottom: 20px;
}

.footer {
  flex: 1.2;
}
</style>