<template>
  <div>
    <b-jumbotron class="container">
      <template #header
        ><h1 class="font-weight-bold">
          Welcome to Punktured Piercing Nottingham
        </h1></template
      >

      <template #lead>
        <p>
          Punktured Piercing Nottingham offer a wide range of body piercings and
          beauty services, we also offer a range of body jewellery and aftercare
          products.
          <br /><br />Our store is located just a few minutes away from the Ice
          Arena in the City Centre.
        </p>
      </template>
    </b-jumbotron>
    <div class="alt-section">
      <b-jumbotron class="container" container-fluid>
        <template #header
          ><h1 class="font-weight-bold">Why Choose Us?</h1>
          <br
        /></template>

        <template #lead>
          <div class="row">
            <div class="why-item col-md-4 item">
              <font-awesome-icon icon="star" size="2x" />
              <h2>Quality Piercings</h2>
              <p>
                All of our piercings are done in a clean enviroment by trained
                staff. <br />Our customer feedback is a testament to this.
              </p>
            </div>
            <div class="why-item col-md-4 item">
              <i class="fa fa-money"></i>
              <font-awesome-icon icon="money-bill" size="2x" />
              <h2>Affordable Prices</h2>
              <p>
                Our piercings are offered at affordable prices, starting at £15
                and our beauty services starting at £6.<br />
              </p>
            </div>
            <div class="why-item col-md-4 item">
              <i class="fas fa-plus-circle"></i>
              <font-awesome-icon icon="plus-circle" size="2x" />
              <h2>Great Aftercare</h2>
              <p>
                We offer both aftercare advice and supplements to help keep your
                piercing clean. If you have any questions or concerns, feel free
                to message us
              </p>
            </div>
          </div>
        </template>
      </b-jumbotron>
    </div>

    <b-jumbotron class="container" container-fluid>
      <template #header
        ><h1 class="font-weight-bold">Featured Photos</h1>
        <br
      /></template>

      <template #lead>
        <div class="row">
          <div class="col-md-4">
            <div class="card featured-photo">
              <a
                href="https://punkturedpiercingnottingham.com/img/featured1.jpg"
                data-lightbox="featured-photos"
                ><img
                  class="img-fluid"
                  src="https://punkturedpiercingnottingham.com/img/featured1.jpg"
                  data=""
              /></a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card featured-photo">
              <a
                href="https://punkturedpiercingnottingham.com/img/featured2.jpg"
                data-lightbox="featured-photos"
                ><img
                  class="img-fluid"
                  src="https://punkturedpiercingnottingham.com/img/featured2.jpg"
              /></a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card featured-photo">
              <a
                href="https://punkturedpiercingnottingham.com/img/featured3.jpg"
                data-lightbox="featured-photos"
                ><img
                  class="img-fluid"
                  src="https://punkturedpiercingnottingham.com/img/featured3.jpg"
              /></a>
            </div>
          </div>
        </div>
      </template>
    </b-jumbotron>
  </div>
</template>
<script>
import "../../node_modules/lightbox2/dist/js/lightbox-plus-jquery.min.js";

export default {};
</script>

<style scoped>
@import "../../node_modules/lightbox2/dist/css/lightbox.min.css";

h1 {
  font-size: 2.5rem;
  text-align: center;
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
  }
}

p {
  font-size: 20px;
  text-align: center;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.jumbotron {
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 2rem 1rem;
}

.alt-section {
  background-color: #ffe4ff;
}

.alt-section > .jumbotron {
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .why-item {
    margin-top: 2rem;
  }
}
</style>