import Vue from 'vue'
import VueRouter from "vue-router"
import App from './App.vue'
import store from './store'

import {BootstrapVue, IconsPlugin} from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faMoneyBill, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faInstagram, faFacebookSquare, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'

library.add(faStar)
library.add(faMoneyBill)
library.add(faPlusCircle)
library.add(faInstagram)
library.add(faFacebookSquare)
library.add(faFacebookMessenger)

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Public
import PublicLayout from "./layouts/Public.vue"
import Home from "./views/Home.vue"
import Services from "./views/Services.vue"
import Contact from "./views/Contact.vue"

import ErrorPage from "./views/Error.vue"

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: PublicLayout,
      children: [
        {
          path: "",
          component: Home,
        },
        {
          path: "services",
          component: Services,
        },
        {
          path: "contact",
          component: Contact,
        },
      ]
    },
    {
      path: "/error/:statusCode",
      component: ErrorPage,
    },
    {
      path: "*",
      component: ErrorPage,
    }
  ]
})

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
