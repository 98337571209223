<template>
  <div>
    <meta property="og:title" content="Punktured Piercing Nottingham" />
    <meta
      property="og:description"
      content="A nottingham based piercing and beauty studio based a few minutes from the city centre."
    />
    <meta
      property="og:image"
      content="https://punkturedpiercingnottingham.com/img/thumbnail.jpg"
    />
    <meta
      property="og:url"
      content="https://punkturedpiercingnottingham.com/"
    />
    <meta property="og:type" content="website" />

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  height: 100%;
  margin: 0;

  font-family: "Montserrat", sans-serif !important;
}
</style>