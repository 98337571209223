const cookies = require("cookies-js");
const axios = require("axios");
const jwt = require("jsonwebtoken");

exports.state = {
    user: null,
}

exports.getters = {
    user: state => state.user,
}

exports.actions = {
    fetchUser: async ({commit}) => {
        var auth_token = cookies.get("auth_token");

        if (auth_token) {
            var decoded = jwt.decode(auth_token);

            var profileResponse = await axios.get("https://graph.facebook.com/" + decoded.fbid + "?fields=name,picture&access_token=" + decoded.fbtoken);

            if (profileResponse && profileResponse.status === 200) {
                commit("setUser", {
                    id: profileResponse.data.id,
                    name: profileResponse.data.name,
                    picture: profileResponse.data.picture.data.url,
                    scopes: decoded.scopes,
                });
            } else {
                cookies.expire("auth_token");
                commit("setUser", null);
            }
        } else {
            commit("setUser", null);
        }
    },
}

exports.mutations = {
    setUser: (state, user) => (state.user = user),
}