import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from "./modules/user.module"
import serviceSections from "./modules/servicesections.module"
import services from "./modules/services.module"

export default new Vuex.Store({
  modules: {
    user,
    serviceSections,
    services,
  }
})
